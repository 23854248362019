/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { Disclosure } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"

import logo from "../../images/logo.svg"
import logo_mobile from "../../images/logo-mobile.svg"

import { v4 as uuidv4 } from "uuid"
import { Link } from "gatsby"

const navigationList = [
  { name: "Startseite", href: "/", current: true },
  { name: "Leistungen", href: "/leistungen", current: false },
  { name: "Ihre Vorteile", href: "/ihre-vorteile", current: false },
  { name: "Projekte", href: "/projekte", current: false },
  { name: "Jobs", href: "/jobs", current: false },
  { name: "Über uns", href: "/ueber-uns", current: false },
  { name: "Kontakt", href: "/kontakt", current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Navigation() {
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 xl:px-0 xl:py-0">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/" className="lg:hidden">
                    <img
                      className="block h-6 lg:h-8 w-auto"
                      src={logo_mobile}
                      alt="Logo"
                    />
                  </Link>
                  <Link to="/" className="lg:block">
                    <img className="hidden h-8 w-auto" src={logo} alt="Logo" />
                  </Link>
                </div>
                <div className="hidden sm:flex sm:space-x-8">
                  {/* Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {navigationList.map(navItem => (
                    <Link
                      key={uuidv4()}
                      to={navItem.href}
                      activeClassName="border-blue-500 text-gray-900 inline-flex items-center  border-b-2 text-md font-medium"
                      className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center border-b-2 text-md font-medium"
                    >
                      {navItem.name}
                    </Link>
                  ))}
                </div>
              </div>

              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {/* Current: "bg-blue-50 border-blue-500 text-blue-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}

              {navigationList.map(navItem => (
                <Link
                  key={uuidv4()}
                  to={navItem.href}
                  activeClassName="bg-blue-50 border-blue-500 text-blue-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  {navItem.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
