import { Link } from "gatsby"
import React from "react"

import logo from "../../images/logo_white.svg"

const navigation = {
  solutions: [
    { name: "Luzern", href: "#" },
    { name: "Analytics", href: "#" },
  ],
  support: [
    { name: "Team", href: "/team" },
    { name: "AGB's", href: "/agbs" },
    { name: "Impressum", href: "/impressum" },
  ],
  company: [
    { name: "About", href: "#" },
    { name: "Blog", href: "#" },
    { name: "Jobs", href: "#" },
    { name: "Press", href: "#" },
    { name: "Partners", href: "#" },
  ],
  legal: [
    { name: "Claim", href: "#" },
    { name: "Privacy", href: "#" },
    { name: "Terms", href: "#" },
  ],
  social: [
    {
      name: "Linkedin",
      href: "https://www.linkedin.com/company/adoser-gmbh/",
      icon: props => (
        <svg
          className="w-5 h-5 text-blue-500"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
            clipRule="evenodd"
          ></path>
        </svg>
      ),
    },
  ],
}

export default function Footer() {
  return (
    <footer className="bg-gray-800" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img className="h-10" src={logo} alt="Company name" />
            <p className="text-white text-base">
              Ihre Zufriedenheit ist unsere Visitenkarte.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map(item => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-blue-500 hover:text-white"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </Link>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-blue-500 tracking-wider uppercase">
                  Standorte
                </h3>
                <ul role="list" className="mt-4 space-y-4 text-white">
                  <li>
                    <h3>Luzern</h3>
                    <address className="text-white font-normal mb-4">
                      Adoser GmbH
                      <br />
                      Emmenweidstrasse 64
                      <br />
                      6020 Emmenbrücke
                    </address>
                    <h3>Appenzell Ausserrhoden</h3>
                    <address className="text-left font-normal">
                      Adoser GmbH
                      <br />
                      Schützenstrasse 38o
                      <br />
                      9100 Herisau
                      <br />
                      Schweiz
                    </address>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-blue-500 tracking-wider uppercase">
                  Sitemap
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map(item => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-white hover:underline"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-white pt-8">
          <p className="text-base text-blue-500 xl:text-center">
            &copy; 2021 Adoser GmbH.
          </p>
        </div>
      </div>
    </footer>
  )
}
